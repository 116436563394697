<template>
  <a-button class="editable-add-btn" @click="openModal" style="margin-bottom: 16px">Добавить</a-button>
  <a-modal
    class="sticker-create-modal"
    title="Добавить стикер"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="create"
    @cancel="resetForm"
  >
    <a-form :model="sticker">
      <div class="col-md-12">
        <a-upload
          name="icon"
          list-type="picture-card"
          class="logo-uploader"
          :show-upload-list="false"
          :headers="getHeaders()"
          :action="getUrl"
          :before-upload="beforeUpload"
          @change="uploadImage"
        >
          <img v-if="iconUrl" :src="iconUrl" alt="avatar"/>
          <div v-else>
            <loading-outlined v-if="loading"></loading-outlined>
            <PlusOutlined v-else></PlusOutlined>
            <div class="ant-upload-text">Логотип</div>
          </div>
        </a-upload>
        <a-form-item label="Название стикера">
          <a-input
            ref="stickerName"
            placeholder="Введите название стикера"
            v-model:value="sticker.text"
          />
        </a-form-item>
        <a-form-item label="Тип стикера">
          <a-select show-search v-model:value="sticker.type">
            <a-select-option :value="1">Сотрудник</a-select-option>
            <a-select-option :value="2">Заведение</a-select-option>
            <a-select-option :value="3">Доставка</a-select-option>
          </a-select>
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {PlusOutlined, LoadingOutlined} from '@ant-design/icons-vue';
import {ref, reactive, toRaw, nextTick} from 'vue';
import {getHeaders} from "@/helpers/header"
import {getBase64} from "@/helpers/base64"
import {message} from 'ant-design-vue';
import apiClient from "@/services/axios";
import Sticker from "@/models/Sticker";

const emit = defineEmits(['reload']);

let iconUrl = ref(''),
  loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(false),
  stickerName = ref("stickerName"),
  sticker = reactive(new Sticker({}));

const
  uploadImage = info => {
    iconUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      sticker.icon = info.file.response.data
      getBase64(info.file.originFileObj, base64Url => {
        iconUrl.value = base64Url;
        loading.value = false;
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  beforeUpload = file => {
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';

    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG or PNG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPngOrSvg && isLt2M;
  },
  openModal = () => {
    modalVisible.value = true;
    sticker.icon = "";
    iconUrl.value = "";
    nextTick(() => stickerName.value?.focus());
  },
  getUrl = () => {
    return process.env.VUE_APP_BACKEND_URL + '/cabinet/sticker/upload'
  },
  resetForm = () => {
    Object.assign(sticker, new Sticker({}));
    modalVisible.value = false;
  },
  create = () => {
    confirmLoading.value = true;

    let model = toRaw(sticker);

    return apiClient.post('/sticker/create', model).then(function (response) {
      if (response) {
        resetForm()
        modalVisible.value = false;
        confirmLoading.value = false;
        emit('reload')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      modalVisible.value = false;
      confirmLoading.value = false;
      emit('reload')
    })
  }
</script>

<style scoped>
@import "./create.css";
</style>
